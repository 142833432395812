import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { SELLER } from '../../../constants/userRoles';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getOrdersPerPage } from '../../../requests/api/order';
import handleApiResponse from '../../../utils/handleApiResponse';
import ordersFiltersActions from '../../../actions/ordersActions';

import useStyles from './styles';

function OrdersListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({
    search: '',
    status: [],
    startDate: '',
    endDate: '',
  });
  const [sendQuery, setSendQuery] = useState(false);
  const ordersRedux = useSelector((state) => state.orders);
  const account = useSelector((state) => state.account);
  const [reduxFilters, setReduxFilters] = useState(false);
  const dispatch = useDispatch();

  let sellerId = 0;

  if (account.user.role === SELLER) {
    sellerId = account.user.seller.id;
  }

  useEffect(() => {
    const setReduxInfo = async () => {
      if (Object.keys(ordersRedux.ordersFilters).length > 0) {
        setFilters({ ...filters, ...ordersRedux.ordersFilters });
        setSendQuery((prev) => !prev);
      }
      setReduxFilters(true);
    };
    setReduxInfo();
  }, []);

  useEffect(() => {
    const getOrders = async () => {
      const joinStatusFilter = filters.status.map((e) => e.value).join(',');
      try {
        const response = await getOrdersPerPage({
          page,
          limit,
          search: filters.search,
          status: joinStatusFilter,
          startDate: filters.startDate,
          endDate: filters.endDate,
          sellerId,
        });
        setOrders(response.orders);
        setTotalOrders(response.totalOrders);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const setOrdersFilters = async () => {
      dispatch(ordersFiltersActions.setOrdersFilters(filters));
    };
    if (reduxFilters) {
      getOrders();
      setOrdersFilters();
    }
  }, [sendQuery, reduxFilters, page, limit]);

  return (
    <Page
      className={classes.root}
      title="Pedidos"
    >
      <Container maxWidth={false}>
        <Header />
        {orders && (
          <Box mt={3}>
            <Results
              orders={orders}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              setSendQuery={setSendQuery}
              totalOrders={totalOrders}
              sellerRole={account.user.role}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default OrdersListView;
