import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { Box, Grid, Typography } from '@mui/material';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

import useStyles from './styles';

function Header({ className, ...rest }) {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Box display="flex" alignItems="center">
        <ShoppingBagIcon className={classes.shoppingBagIcon}/>
          <Typography
            className={classes.headerTitle}
          >
            Detalle del producto
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
