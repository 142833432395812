import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { Grid, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import useStyles from './styles';

function Header({ className, ...rest }) {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
      <div className={classes.headerTitleDiv}>
        <AttachMoneyIcon className={classes.shoppingBagIcon}/>
          <Typography
            className={classes.headerTitle}
          >
            Detalle del vendedor
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
