const productActiveOptions = [
  { value: 'active', label: 'Activo' },
  { value: 'inactive', label: 'Inactivo' },
];

const productStockOptions = [
  { value: 'yes', label: 'Sí' },
  { value: 'no', label: 'No' },
];

const clientStatusOptions = [
  { value: 'Activo' },
  { value: 'Bloqueado' },
];

const orderStatusOptions = [
  { value: 'Facturado' },
  { value: 'Pendiente' },
];

const clientPaymentConditionsOptions = [
  { value: 'C/E  Pago en efectivo' },
  { value: 'Abono bancario' },
  { value: 'Pago a 10 días' },
  { value: 'Ingreso manual de vencimiento' },
  { value: 'Contado' },
  { value: 'Cheque adjunto al día' },
  { value: 'Cheque adj. vct fact 07 días' },
  { value: 'Cheque adj. vct fact 15 días' },
  { value: 'Cheque adj. vct fact 30 días' },
  { value: 'Cheque adj. vct fact 45 días' },
  { value: 'Cheque adj. vct fact 60 días' },
  { value: 'Cheque adj. vct fact 75 días' },
  { value: 'Cheque adj. vct fact 90 días' },
  { value: 'Cheque adj. vct fact 120 días' },
  { value: 'C/E cheque vcto fact 03 días' },
  { value: 'Contra entrega cheque al día' },
  { value: 'C/E cheque vcto fact 07 días' },
  { value: 'C/E cheque vcto fact 15 días' },
  { value: 'C/E cheque vcto fact 30 días' },
  { value: 'C/E cheque vcto fact 45 días' },
  { value: 'C/E cheque vcto fact 60 días' },
  { value: 'C/E cheque vcto fact 75 días' },
  { value: 'C/E cheque vcto fact 90 días' },
  { value: 'C/E cheque vcto fact 120 días' },
  { value: 'Crédito a 03 días' },
  { value: 'Crédito  0 día  (carga inicial)' },
  { value: 'Crédito a 01 día' },
  { value: 'Crédito a 07 días' },
  { value: 'Crédito a 15 días' },
  { value: 'Crédito a 30 días' },
  { value: 'Crédito a 45 días' },
  { value: 'Crédito a 60 días' },
  { value: 'Crédito a  75 días' },
  { value: 'Crédito a 90 días' },
  { value: 'Crédito a 120 días' },
  { value: 'Crédito a  70 días' },
  { value: 'Crédito a 255 días' },
  { value: 'Cheque a fecha 07 días' },
  { value: 'Cheque a fecha 15 días' },
  { value: 'Cheque a fecha 30 días' },
  { value: 'Cheque a fecha 45 días' },
  { value: 'Cheque a fecha 60 días' },
  { value: 'Cheque a fecha 75 días' },
  { value: 'Cheque a fecha 90 días' },
  { value: 'LE $ al vcto. fact 07 días' },
  { value: 'LE $ al vcto. fact 15 días' },
  { value: 'LE $ al vcto. fact 30 días' },
  { value: 'LE $ al vcto. fact 45 días' },
  { value: 'LE $ al vcto. fact 60 días' },
  { value: 'LE $ al vcto. fact 75 días' },
  { value: 'LE $ al vcto. fact 90 días' },
  { value: 'LE $ al vcto. fact 120 días' },
  { value: 'LE US$ al vcto. fact 07 días' },
  { value: 'LE US$ al vcto. fact 15 días' },
  { value: 'LE US$ al vcto. fact 30 días' },
  { value: 'LE US$ al vcto. fact 45 días' },
  { value: 'LE US$ al vcto. fact 60 días' },
  { value: 'LE US$ al vcto. fact 75 días' },
  { value: 'LE US$ al vcto. fact 90 días' },
  { value: 'LE US$ al vcto. fact 120 días' },
  { value: 'LE UF al vcto. fact 07 días' },
  { value: 'LE UF al vcto. fact 15 días' },
  { value: 'LE UF al vcto. fact 30 días' },
  { value: 'LE UF al vcto. fact 45 días' },
  { value: 'LE UFal vcto. fact 60 días' },
  { value: 'LE UF al vcto. fact 90 días' },
  { value: 'LE UF al vcto. fact 120 días' },
  { value: 'Carta Crédito' },
  { value: 'Carta Crédito 30 días' },
  { value: 'Carta Crédito 45 días' },
  { value: 'Carta Crédito 60 días' },
  { value: 'Carta Crédito 90 días' },
  { value: 'Carta Crédito 100 días' },
  { value: 'Carta Crédito 105 días' },
  { value: 'Carta Crédito 110 días' },
  { value: 'Carta Crédito 115 días' },
  { value: 'Carta Crédito 120 días' },
  { value: 'Carta Crédito 125 días' },
  { value: 'Carta Crédito 130 días' },
  { value: 'Carta Crédito 135 días' },
  { value: 'Carta Crédito 140 días' },
  { value: 'Carta Crédito 145 días' },
  { value: 'Carta Crédito 150 días' },
  { value: 'Carta Crédito 165 días' },
  { value: 'Carta Crédito 180 días' },
  { value: 'Carta Crédito 195 días' },
  { value: 'Carta Crédito 210 días' },
  { value: 'Carta Crédito 225 días' },
  { value: 'Carta Crédito 240 días' },
  { value: 'Carta Crédito 255 días' },
  { value: 'Carta Crédito 270 días' },
  { value: 'Carta Crédito 285 días' },
  { value: 'Carta Crédito 300 días' },
  { value: 'Carta Crédito 315 días' },
  { value: 'Carta Crédito 330 días' },
  { value: 'Carta Crédito 345 días' },
  { value: 'Carta Crédito 360 días' },
  { value: 'Cobranza COMEX' },
  { value: 'Cobranza 30 días COMEX' },
  { value: 'Cobranza 45 días COMEX' },
  { value: 'Cobranza 60 días COMEX' },
  { value: 'Cobranza 90 días COMEX' },
  { value: 'Cobranza 100 días COMEX' },
  { value: 'Cobranza 105 días COMEX' },
  { value: 'Cobranza 110 días COMEX' },
  { value: 'Cobranza 115 días COMEX' },
  { value: 'Cobranza 120 días COMEX' },
  { value: 'Cobranza 125 días COMEX' },
  { value: 'Cobranza 130 días COMEX' },
  { value: 'Cobranza 135 días COMEX' },
  { value: 'Cobranza 140 días COMEX' },
  { value: 'Cobranza 145 días COMEX' },
  { value: 'Cobranza 150 días COMEX' },
  { value: 'Cobranza 360 días COMEX' },
  { value: 'Cobranza 210 días COMEX' },
  { value: 'Cobranza 180 días COMEX' },
  { value: 'Cobranza 75 días COMEX' },
  { value: 'Pago contado efectivo (P)' },
  { value: 'Abono bancario (P)' },
  { value: 'Según texto en orden de compra (P)' },
  { value: 'Pago inmediato (P)' },
  { value: 'Credito a 05 dias (P)' },
  { value: 'Crédito a 07 días (P)' },
  { value: 'Crédito a 10 días (P)' },
  { value: 'Crédito a 15 días (P)' },
  { value: 'Crédito a 20 días (P)' },
  { value: 'Crédito a 25 días (P)' },
  { value: 'Crédito a 30 días (P)' },
  { value: 'Crédito a 35 días (P)' },
  { value: 'Crédito a 40 días (P)' },
  { value: 'Crédito a 45 días (P)' },
  { value: 'Crédito a 50 días (P)' },
  { value: 'Crédito a 55 días (P)' },
  { value: 'Crédito a 60 días (P)' },
  { value: 'Crédito a 75 días (P)' },
  { value: 'Crédito a 90 días (P)' },
  { value: 'Crédito a 100 días (P)' },
  { value: 'Crédito a 120 días (P)' },
  { value: 'Crédito a 37 días (P)' },
  { value: 'Crédito a 180 días (P)' },
  { value: 'Crédito a 70 días (P)' },
  { value: 'Crédito a 105 días (P)' },
  { value: 'Crédito a 80 días (P)' },
  { value: 'Crédito a  80 días' },
  { value: 'Vencimiento al 727 de cada mes' },
  { value: 'Vencimiento al 694 de cada mes' },
  { value: 'Vencimiento al 666 de cada mes' },
  { value: 'Vencimiento al 635 de cada mes' },
  { value: 'Vencimiento al 605 de cada mes' },
  { value: 'Vencimiento al 574 de cada mes' },
  { value: 'Vencimiento al 543 de cada mes' },
  { value: 'Vencimiento al 513 de cada mes' },
  { value: 'Vencimiento al 482 de cada mes' },
  { value: 'Vencimiento al 452 de cada mes' },
  { value: 'Vencimiento al 419 de cada mes' },
  { value: 'Vencimiento al 391 de cada mes' },
  { value: 'Vencimiento al 360 de cada mes' },
  { value: 'Vencimiento al 330 de cada mes' },
  { value: 'Vencimiento al 300 de cada mes' },
  { value: 'Vencimiento al 270 de cada mes' },
  { value: 'Vencimiento al 240 de cada mes' },
  { value: 'Vencimiento al 210 de cada mes' },
  { value: 'Vencimiento al 180 de cada mes' },
  { value: 'Vencimiento al 150 de cada mes' },
  { value: 'Vencimiento al 120 de cada mes' },
  { value: 'Vencimiento al 90 de cada mes' },
  { value: 'Vencimiento al 60 de cada mes' },
  { value: 'Vencimiento al 30 de cada mes' },
];
module.exports = {
  productActiveOptions,
  productStockOptions,
  clientPaymentConditionsOptions,
  clientStatusOptions,
  orderStatusOptions,
};
