/* eslint-disable max-len */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import moment from 'moment';
import pesoChileno from '../../../utils/moneyFormat';
import useStyles from './styles';

const DataChart = ({ client, chart }) => {
  const classes = useStyles();
  const dataX = [];
  const dataY1 = [];
  let chartName = '';
  const seriesData = [];
  let chartType = 'line';
  let enabledTooltip = true;

  const { monthsTotalInvoices } = client;
  const { clientArticleGroups } = client;

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  if (Object.keys(client).length === 0) {
    return null;
  }

  if (chart === 2) {
    for (let i = 0; i < clientArticleGroups.length; i += 1) {
      const articleGroup = clientArticleGroups[i];
      const { name } = articleGroup.articleGroup;
      const { total } = articleGroup;
      dataX.push(name);
      dataY1.push({ name, y: total, label: pesoChileno(total) });
      chartName = 'Venta';
      chartType = 'pie';
      enabledTooltip = false;
    }
  } else {
    for (let i = 12; i >= 0; i -= 1) {
      const date = new Date(currentYear, currentMonth - i, 1);
      const momentMonthAndYear = moment(date).format('MM-YYYY');
      const month = date.toLocaleString('default', { month: 'long' });
      const { totalAmountPurchase } = monthsTotalInvoices[momentMonthAndYear];
      const { totalInvoices } = monthsTotalInvoices[momentMonthAndYear];
      dataX.push(month);
      if (chart === 0) {
        dataY1.push(totalAmountPurchase);
        chartName = 'Venta';
      }
      if (chart === 1) {
        const ticketMedio = totalAmountPurchase / totalInvoices || 0;
        dataY1.push(ticketMedio);
        chartName = 'Ticket medio';
      }
    }
  }

  seriesData.push({
    data: dataY1,
    color: '#62A606',
  });

  const chartOptions = {
    chart: {
      type: chartType,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: dataX,
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        style: {
          color: '#24335E',
          fontFamily: 'Inter, sans-serif',
          textTransform: 'capitalize',
          fontSize: '16px',
          fontWeight: 700,
        },
      },
    },
    yAxis: {
      stackLabels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      labels: {
        enabled: true,
      },
      gridLineWidth: 0,
    },
    legend: {
      verticalAlign: 'top',
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
      },
      column: {
        borderWidth: 0,
        shadow: false,
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}: {point.percentage:.2f}%</b><br/>{point.label}',
        },
        borderWidth: 0,

      },
    },
    // colors: ['#62A606', '#24335E'],
    tooltip: {
      enabled: enabledTooltip,
      backgroundColor: '#D9D9D9',
      borderWidth: 1,
      borderRadius: 5,
      useHTML: true,
      style: {
        fontFamily: 'Inter, sans-serif',
        color: '#24335E',
      },
      formatter() {
        const { series } = this.series.chart;
        const categoryIndex = this.point.index;
        const valueY1 = series[0].yData[categoryIndex];

        if (chart === 0 || chart === 1 || chart === 2) {
          return `<div style="text-align: center;"><b style="font-weight: 700">${chartName}</b><br/>${pesoChileno(
            valueY1,
          )}</div>`;
        }
        return null;
      },
    },
    series: seriesData,
  };

  return (
    <div className={classes.progressChartDiv}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

DataChart.propTypes = {
  client: PropTypes.object.isRequired,
  chart: PropTypes.number.isRequired,
};

export default DataChart;
