/* eslint-disable max-len */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import moment from 'moment';
import pesoChileno from '../../../utils/moneyFormat';
import useStyles from './styles';

const ProgressChart = ({ seller, chart }) => {
  const classes = useStyles();
  const dataX = [];
  const dataY1 = [];
  const dataY2 = [];
  let chartName = '';
  const seriesData = [];
  let chartType = 'line';
  let enabledTooltip = true;
  let enabledLegend = false;
  let nameY1 = '';
  let nameY2 = '';

  const { monthsObjectivesAndTotalSell } = seller;
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  if (Object.keys(seller).length === 0) {
    return null;
  }

  if (chart === 3) {
    for (let i = 0; i <= 11; i += 1) {
      const date1 = new Date(currentYear, 0 + i, 1);
      const date2 = new Date(currentYear - 1, 0 + i, 1);
      const dateTime1 = moment(date1).format('MM-YYYY');
      const dateTime2 = moment(date2).format('MM-YYYY');
      const month = date1.toLocaleString('default', { month: 'long' });
      const totalClientDirections1 = monthsObjectivesAndTotalSell[dateTime1]?.totalClientDirections || 0;
      const totalClientDirections2 = monthsObjectivesAndTotalSell[dateTime2]?.totalClientDirections || 0;

      dataX.push(month);
      dataY1.push(totalClientDirections1);
      dataY2.push(totalClientDirections2);
      nameY1 = date1.getFullYear();
      nameY2 = date2.getFullYear();
    }
    enabledLegend = true;
    chartName = 'Cobertura';
  }

  if (chart === 4) {
    const { sellerTotalSellByArticleGroup } = seller;
    for (let i = 0; i < sellerTotalSellByArticleGroup.length; i += 1) {
      const articleGroup = sellerTotalSellByArticleGroup[i];
      const { name } = articleGroup.articleGroup;
      const { totalSell } = articleGroup;
      const peso = pesoChileno(totalSell);
      dataX.push(name);
      dataY1.push({ name, y: totalSell, label: peso });
      chartName = 'Venta';
      chartType = 'pie';
      enabledTooltip = false;
    }
  } else {
    for (let i = 12; i >= 0; i -= 1) {
      const date = new Date(currentYear, currentMonth - i, 1);
      const momentMonthAndYear = moment(date).format('MM-YYYY');
      const month = date.toLocaleString('default', { month: 'long' });
      const { totalSell } = monthsObjectivesAndTotalSell[momentMonthAndYear];
      const { totalInvoices } = monthsObjectivesAndTotalSell[momentMonthAndYear];
      dataX.push(month);
      if (i <= 12) {
        if (chart === 0) {
          dataY1.push(totalSell);
          chartName = 'Venta';
        }
        if (chart === 1) {
          dataY1.push(totalInvoices);
          chartName = 'Numero de pedidos';
        }
        if (chart === 2) {
          const ticketMedio = totalSell / totalInvoices || 0;
          dataY1.push(ticketMedio);
          chartName = 'Ticket medio';
        }
      }
    }
  }

  seriesData.push(
    {
      name: nameY2,
      data: dataY2,
      color: '#24335E',
    },
    {
      name: nameY1,
      data: dataY1,
      color: '#62A606',
    },
  );

  const chartOptions = {
    chart: {
      type: chartType,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: dataX,
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        style: {
          color: '#24335E',
          fontFamily: 'Inter, sans-serif',
          textTransform: 'capitalize',
          fontSize: '16px',
          fontWeight: 700,
        },
      },
    },
    yAxis: {
      stackLabels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      labels: {
        enabled: true,
      },
      gridLineWidth: 0,
    },
    legend: {
      verticalAlign: 'top',
      enabled: enabledLegend,
      itemStyle: {
        fontSize: '16px',
        fontFamily: 'Inter, sans-serif',
        color: '#5F696B',
        fontWeight: 700,
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
      },
      column: {
        borderWidth: 0,
        shadow: false,
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}: {point.percentage:.2f}%</b><br/>{point.label}',
        },
        borderWidth: 0,
      },
    },
    tooltip: {
      enabled: enabledTooltip,
      backgroundColor: '#D9D9D9',
      borderWidth: 1,
      borderRadius: 5,
      useHTML: true,
      style: {
        fontFamily: 'Inter, sans-serif',
        color: '#24335E',
      },
      formatter() {
        const { series } = this.series.chart;
        const seriesIndex = this.series.index;
        const categoryIndex = this.point.index;
        const valueY1 = series[0].yData[categoryIndex];
        const valueY2 = series[1].yData[categoryIndex];

        if (chart === 0 || chart === 2 || chart === 4) {
          return `<div style="text-align: center;"><b style="font-weight: 700">${chartName}</b><br/>${pesoChileno(
            valueY2,
          )}</div>`;
        }
        if (chart === 1) {
          return `<div style="text-align: center;"><b style="font-weight: 700">${chartName}</b><br/>${valueY2}</div>`;
        }
        if (chart === 3) {
          if (valueY1 === valueY2) {
            return `<div style="text-align: center;"><b style="font-weight: 700">${chartName}</b><br/>${currentYear - 1}: ${valueY1} <br/>${currentYear}: ${valueY2}</div>`;
          }
          if (seriesIndex === 1) {
            return `<div style="text-align: center;"><b style="font-weight: 700">${chartName}</b><br/>${currentYear}: ${valueY2}</div>`;
          }
          if (seriesIndex === 0) {
            return `<div style="text-align: center;"><b style="font-weight: 700">${chartName}</b><br/>${currentYear - 1}: ${valueY1}</div>`;
          }
        }
        return null;
      },
    },
    series: seriesData,
  };

  return (
    <div className={classes.progressChartDiv}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

ProgressChart.propTypes = {
  seller: PropTypes.object.isRequired,
  chart: PropTypes.number.isRequired,
};

export default ProgressChart;
