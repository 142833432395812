import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import { SELLER } from '../../../constants/userRoles';
import { getClientsPerPage } from '../../../requests/api/client';
import handleApiResponse from '../../../utils/handleApiResponse';
import clientsFiltersActions from '../../../actions/clientsActions';

import useStyles from './styles';

function ClientsListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [clients, setClients] = useState([]);
  const [totalClients, setTotalClients] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({
    search: '',
    status: [],
    paymentConditions: [],
  });
  const [sendQuery, setSendQuery] = useState(false);
  const clientsRedux = useSelector((state) => state.clients);
  const account = useSelector((state) => state.account);
  const [reduxFilters, setReduxFilters] = useState(false);
  const dispatch = useDispatch();

  let sellerId = 0;

  if (account.user.role === SELLER) {
    sellerId = account.user.seller.id;
  }

  useEffect(() => {
    const setReduxInfo = async () => {
      if (Object.keys(clientsRedux.clientsFilters).length > 0) {
        setFilters(clientsRedux.clientsFilters);
        setSendQuery((prev) => !prev);
      }
      setReduxFilters(true);
    };
    setReduxInfo();
  }, []);

  useEffect(() => {
    const getClients = async () => {
      const joinStatusFilter = filters.status.map((e) => e.value).join(',');
      const joinPaymentConditionsFilter = filters.paymentConditions.map((e) => e.value).join(',');
      try {
        const response = await getClientsPerPage({
          page,
          limit,
          search: filters.search,
          status: joinStatusFilter,
          paymentConditions: joinPaymentConditionsFilter,
          sellerId,
        });
        setClients(response.clients);
        setTotalClients(response.totalClients);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    const setClientsFilters = async () => {
      dispatch(clientsFiltersActions.setClientsFilters(filters));
    };
    if (reduxFilters) {
      getClients();
      setClientsFilters();
    }
  }, [sendQuery, reduxFilters, page, limit]);

  return (
    <Page className={classes.root} title="Clientes">
      <Container maxWidth={false}>
        <Header />
        {clients && (
          <Box mt={3}>
            <Results
              clients={clients}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              setSendQuery={setSendQuery}
              totalClients={totalClients}
              sellerRole={account.user.role}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default ClientsListView;
