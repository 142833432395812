import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { putProductById } from '../../../requests/api/product';

function Product({
  className,
  product,
  setSendQuery,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  if (Object.keys(product).length === 0) {
    return null;
  }

  const handleEditProduct = async () => {
    const data = {
      productId: product.id,
      active: !product.active,
    };

    const response = await putProductById(data);
    if (response.success) {
      enqueueSnackbar('Producto editado correctamente', {
        variant: 'success',
      });
      setSendQuery((prev) => !prev);
    } else {
      enqueueSnackbar(response.data.errorMessage, {
        variant: 'warning',
      });
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Código</TableCell>
                <TableCell align="center">Nombre</TableCell>
                <TableCell align="center">Unidad base</TableCell>
                <TableCell align="center">ILA</TableCell>
                <TableCell align="center">IVA</TableCell>
                <TableCell align="center">Activo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover key={product.id}>
                <TableCell align="center">
                  <Typography color="inherit" variant="h6">
                    {product.sapId}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography color="inherit" variant="h6">
                    {product.description}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography color="inherit" variant="h6">
                    {product.baseUnit}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography color="inherit" variant="h6">
                    {`${product.ila * 100}%`}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography color="inherit" variant="h6">
                    {`${product.iva * 100}%`}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={product.active ? 'Desactivar producto' : 'Activar producto'}>
                    <Switch
                      className={classes.switch}
                      checked={product.active}
                      onChange={handleEditProduct}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

Product.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
  setSendQuery: PropTypes.func,
};

Product.defaultProps = {
  product: {},
};

export default Product;
